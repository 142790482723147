<template>
  <!-- confirm Dupicate -->
  <div
    class="popup-container align-items-center"
    v-show="blnAltDupicateAcademic == true && intWrongLogin < intPinCount"
  >
    <transition name="zoom">
      <AlertPopup
        type="Danger"
        :acorgSub="true"
        v-show="blnAltDupicateAcademic == true && intWrongLogin < intPinCount"
        class="warning"
        classOkBtn="btn-warning text-white"
        classCancelBtn="text-warning"
        artIcon="WarningOutline"
        :Helper="$t('355')"
        @confirm="confirmAltPopup($event)"
        :blnLoading="blnloadConfirmDelet"
        :subtitleLine1="$t('411')"
        :subtitleLine2="$t('412')"
        :showpass="true"
        :btnpop="true"
        @onRadio="getIdRadio($event)"
        :strButtonSubmit="$t('36')"
        :errorMsg="
          blnError == true && blnchangeMsg == false
            ? $t('357', { number: intPinCount - intWrongLogin })
            : $t('284', { fieldname: $t('19').toLowerCase() })
        "
        :blnError="blnError"
        ref="DupicateAcademic"
      />
    </transition>
    <transition name="fade">
      <div
        class="overlay"
        v-show="blnAltDupicateAcademic == true"
        @click="blnloadConfirmDelet == false ? confirmAltPopup(false) : ''"
      ></div>
    </transition>
  </div>

  <!-- confirmDelete -->
  <div
    class="popup-container align-items-center"
    v-show="blnPopupConfirmDelete == true && intWrongLogin < intPinCount"
  >
    <transition name="zoom">
      <Alert
        v-show="blnPopupConfirmDelete == true && intWrongLogin < intPinCount"
        type="Danger"
        classOkBtn="btn-danger"
        artIcon="deleteFill"
        :altMsgBox="$t('186', { fieldname: $t('12').toLowerCase() })"
        :Helper="$t('345')"
        @confirm="fetchDeleteAcademic($event)"
        :blnLoading="blnloadConfirmDelet"
        ref="AlertPopup"
        :showpass="true"
        :btnpop="true"
        classIcon="fill-danger"
        :errorMsg="$t(errorMsg)"
        :blnError="blnError"
      />
    </transition>
    <transition name="fade">
      <div
        class="overlay"
        v-show="blnPopupConfirmDelete == true && intWrongLogin < intPinCount"
        @click="blnloadConfirmDelet == false ? ConfirmDelete() : ''"
      ></div>
    </transition>
  </div>

  <!-- go to login page -->
  <div
    class="popup-container align-items-center"
    v-show="blnPopupConfirmDelete == true && intWrongLogin >= intPinCount"
  >
    <transition name="zoom">
      <Alert
        v-show="blnPopupConfirmDelete == true && intWrongLogin >= intPinCount"
        type="Warning"
        classOkBtn="btn-secondary"
        artIcon="WarningOutline"
        :altMsgBox="$t('1287', { LONGINFAILNUM: intPinCount, LockContact: strTel })"
        :Helper="$t('345')"
        @confirm="reloadpage()"
        ref="AlertPopupp"
        :strButtonSubmit="$t('36')"
        classIcon="fill-warning"
        :Cancel="false"
      />
    </transition>
    <transition name="fade">
      <div
        class="overlay"
        v-show="blnPopupConfirmDelete == true && intWrongLogin >= 3"
      ></div>
    </transition>
  </div>

  <AltStatus
    :blnalert="blnAltStatus"
    icon="CorrectFill"
    coloricon="fill-success"
    class="success"
    :name="$t(strSMSGsuccess)"
    :class="blnAltStatus ? 'show' : ''"
  />
  <AltStatus
    :blnalert="blnAltStatusunsucess"
    icon="IncorrectFill"
    coloricon="fill-danger"
    class="error"
    :name="$t('174')"
    @mousemove="hover = true"
    @mouseleave="hover = false"
    :class="blnAltStatusunsucess ? 'show' : ''"
  />
  <AltStatus
    :blnalert="blnAltLoadingData"
    icon="IncorrectFill"
    coloricon="fill-danger"
    class="error"
    :name="$t('174')"
    @mousemove="hover = true"
    @mouseleave="hover = false"
    :class="blnAltLoadingData ? 'show' : ''"
  />
  <AltStatus
    :blnalert="blnAltLoading"
    icon="IncorrectFill"
    coloricon="fill-danger"
    class="error"
    :name="$t('172')"
    @mousemove="hover = true"
    @mouseleave="hover = false"
    :class="blnAltLoading ? 'show' : ''"
  />
  <AltStatus
    :blnalert="blnnotdelete"
    icon="IncorrectFill"
    coloricon="fill-danger"
    class="error"
    :name="$t('428')"
    @mousemove="hover = true"
    @mouseleave="hover = false"
    :class="blnnotdelete ? 'show' : ''"
  />
  <AltStatus
    :blnalert="blnnotedit"
    icon="IncorrectFill"
    coloricon="fill-danger"
    class="error"
    :name="$t('222')"
    @mousemove="hover = true"
    @mouseleave="hover = false"
    :class="blnnotedit ? 'show' : ''"
  />
  <AltStatus
    :blnalert="blnnotaddchild"
    icon="IncorrectFill"
    coloricon="fill-danger"
    class="error"
    :name="$t('429')"
    @mousemove="hover = true"
    @mouseleave="hover = false"
    :class="blnnotaddchild ? 'show' : ''"
  />
  <AltStatus
    :blnalert="blnstatusOpent"
    icon="IncorrectFill"
    coloricon="fill-danger"
    class="error"
    :name="$t('430')"
    @mousemove="hover = true"
    @mouseleave="hover = false"
    :class="blnstatusOpent ? 'show' : ''"
  />

  <Header
    icon="agencyOutline"
    :headerName="blnOpenform == true ? $t('420') : $t('12')"
    :btnName="$t('165')"
    :breadcrumbname="
      action == 'Insert' ? $t('165') : '' || action == 'Update' ? $t('400') : ''
    "
    class="mb-4"
    classAutoSearch="input-group-large"
    @onInput="
      {
        action = 'Insert';
        openform($event, intCampusId, 0);
      }
    "
    :breadcrumb="blnOpenform == true ? '' : 'd-none'"
    :helperHeader="
      blnOpenform == true
        ? $t('200', { fieldname: $t('420').toLowerCase() })
        : ''
    "
    :hintHelperHeader="blnOpenform == true ? '' : 'd-none'"
    :rightHeader="blnOpenform == true ? 'd-none' : ''"
    :underline="blnOpenform == true ? 'openUnderline' : 'closeUnderline'"
    @showFilter="onOpenFilter($event)"
    id="header"
    :Searchname="$t('164')"
    @onSearch="SearcAgency($event)"
    @onClean="clean()"
    :blnOpenform="blnOpenform"
    :showFilter="blnOpenFilter"
    :filterActive="{
      blnOpenform: blnOpenFilter,
      useFilter:
        activeFiltersSelected.length == 0 || activeFiltersSelected.length == 4
          ? false
          : true,
      disableFilter: false,
    }"
    :dataselection="Listcampus.data"
    @selectOption="selectCampus($event)"
    ref="header"
  />
  <!-- <div class="bg-white admin-form-campus rounded-2 shadow-sm ms-9 mb-5">
    <StatusCode
      codeNumber="500"
      detail="There was an error. Please try again later."
      message="Internal server error"
    />
  </div> -->
  <div
    v-show="blnOpenform == false"
    class="px-4 pt-4 pb-5 bg-white rounded-2 shadow-sm"
  >
    <!-- <Select
      strName="Campus"
      class="d-none"
    /> -->
    <div
      class="select-campus mb-2"
      :class="ListCampusClass"
      :style="
        device == 'mobile' ? 'width: 100% !important' : 'width: fit-content'
      "
    >
      <Dropdown
        :blnReturnAll="false"
        :strName="$t('2')"
        @selected="selectCampus($event)"
        :listDataSelection="Listcampus.data"
        :blnSearchBox="true"
        :blnDefaultSelected="true"
        :alertMsg="$t('284', { fieldname: $t('3').toLowerCase() })"
        size="dropdown-resize"
        ref="Dropdown"
      />
      <!-- <select
        v-if="locales == 'en'"
        aria-label="Default select example"
        class="border form-select text-primary dropdown-header mb-2"
        @change="selectCampus($event.target.value)"
      >
        <option
          v-for="(op, index) in Listcampus.data"
          :key="index"
          style="height: 48px !important"
          class="text-primary"
          :value="op['strID']"
          :selected="op['strID'] == intCampusId ? true : false"
        >
          {{ op["strCampusNameEN"] }}
        </option>
      </select>
      <select
        v-if="locales == 'th'"
        aria-label="Default select example"
        class="border form-select text-primary dropdown-header mb-2"
        @change="selectCampus($event.target.value)"
      >
        <option
          v-for="(op, index) in Listcampus.data"
          :key="index"
          style="height: 48px !important"
          class="text-primary"
          :value="op['strID']"
          :selected="op['strID'] == intCampusId ? true : false"
        >
          {{ op["strCampusNameTH"] }}
        </option>
      </select> -->
    </div>
    <AgengyTable
      :progressScore="progress"
      fieldStrname="strAcorgnameEN"
      :ListDataTable="ListAcademic"
      secondSort="strAcorgnameEN"
      firstSort="intAcorgstatus"
      ref="table"
      :blnTooltip="true"
      :tooltipMsg="$t('1272')"
      tooltipPlacement="tooltip-left-edit"
      @ConfigDisplay="reloadConfigDisplay()"
      @onSaveConfigDisplay="onSaveConfigDisplay($event)"
      @addParent="
        // {
        //   action = 'Insert';
        //   openform(true, intCampusId, $event);
        // }
        addParent(intCampusId, $event)
      "
      @edit="fetchGetAcademicbyId($event)"
      :nametable="'12'"
    />
  </div>
  <div class="popup-container" v-show="blnOpenFilter == true">
    <transition name="zoom">
      <Filter
        v-show="blnOpenFilter == true"
        @appectFilter="filteredData($event)"
        :listDataFilter="listAcademicFilter"
        @Cancel="blnOpenFilter = !blnOpenFilter"
        :listActiveFiltersSelected="activeFiltersSelected"
        :blnHaveDecision="false"
        :blnOpenFilter="blnOpenFilter"
        ref="filter"
        btnFix="btn-group-fix"
        classFilter="filter-fix"
      />
    </transition>
    <transition name="fade">
      <div
        class="overlay"
        v-show="blnOpenFilter == true"
        @click="blnOpenFilter = false"
      ></div>
    </transition>
  </div>
  <div
    class="d-flex mt-sm-3 ms-lg-9 m-sm-0 justify-content-start align-items-start"
    v-if="blnOpenform == true"
    :class="
      device == 'mobile' || device == 'tablet'
        ? 'flex-wrap move-to-top-level'
        : ''
    "
  >
    <div
      class="form-size rounded-2 shadow-sm bg-white me-lg-5 me-sm-0 layout"
      :class="device == 'mobile' ? 'mb-5' : 'mb-4'"
    >
      <div
        class="p-5 d-flex flex-column gap-5 justify-content-center"
        style="width: 886px; height: 480px"
        v-if="blnloading == true"
      >
        <div class="skeleton rounded" style="width: 100%; height: 60px">
          <div class="skeleton-item rounded mb-3"></div>
        </div>
        <div class="d-flex gap-3">
          <div class="skeleton rounded" style="width: 70%; height: 30px">
            <div class="skeleton-item rounded mb-3"></div>
          </div>
          <div class="skeleton rounded" style="width: 30%; height: 30px">
            <div class="skeleton-item rounded mb-3"></div>
          </div>
        </div>
        <div class="d-flex gap-3">
          <div class="skeleton rounded" style="width: 70%; height: 30px">
            <div class="skeleton-item rounded mb-3"></div>
          </div>
          <div class="skeleton rounded" style="width: 30%; height: 30px">
            <div class="skeleton-item rounded mb-3"></div>
          </div>
        </div>
        <div class="skeleton rounded" style="width: 70%; height: 30px">
          <div class="skeleton-item rounded mb-3"></div>
        </div>
      </div>
      <div v-show="blnloading == false">
        <Form
          v-if="(blnOpenform = true)"
          :typeSchema="typeSchema"
          :data="formdata"
          @onInput="openform($event)"
          @Insert="fetchCheckDupicateAcademic($event)"
          @Update="fetchCheckDupicateUpdateAcademic($event)"
          @delete="ConfirmDelete()"
          :action="action"
          :request="request"
          ref="form"
          :inactive="formInactive"
        />
      </div>
    </div>
    <Relate
      :class="device == 'mobile' || device == 'tablet' ? 'mt-3 mb-3 w-100' : ''"
      v-if="blnOpenform == true && listRelate.used == true"
      :listRelate="listRelate"
      :label="$t('418', { fieldname: $t('12').toLowerCase() })"
      style="width: 350px"
      class="layout"
    />
  </div>
</template>
<script>
import Relate from "@/components/cards/relate.vue";
import AgengyTable from "@/components/table/tableAgt.vue";
import Header from "@/components/headerMenu/haeder.vue";
import Form from "@/components/form/form.vue";
import {
  typeSchema,
  request,
} from "@/components/constants/schemaAddAcademicOrganization";
import Filter from "@/components/filter/filter.vue";
//import apiacademic from "../typescript/urlapi/adminsystem/apiacademic";
import APICampus from "@/typescript/urlapi/adminsystem/APICampus";
import apiacademic from "@/typescript/urlapi/adminsystem/APIAcademic";
import AlertPopup from "@/components/alert/alertPopup.vue";
import axios from "axios";
import urlapi from "@/typescript/urlapi/url";
//import StatusCode from "../components/statuscode/error.vue";
import group from "@/typescript/group/group";
import AltStatus from "@/components/alert/alertStatus.vue";
import Alert from "@/components/alert/alertPopup.vue";
import AdsInventories from "@/typescript/inventory";
import Login from "@/typescript/login";
import Dropdown from "@/components/input/dropdownapidata.vue";
import cookiesPath from "@/typescript/urlapi/cookiesPath";
import VueCookies from "vue-cookies";
export default {
  data() {
    return {
      blnloadConfirmDelet: false,
      blnloading: false,
      blnchangeMsg: false,
      parentstatus: null,
      blnCanDelete: null,
      DropdownID: "",
      firtDropdowns: "",
      blnischildinactive: false,
      blnError: false,
      listRelate: [],
      intPinCount: 1,
      intWrongLogin: 0,
      blnPopupConfirmDelete: false,
      locales: null,
      formInactive: [], //Input disabled
      progress: 0,
      configDisplay: "SelectedListAcademic",
      strSearchField: [
        "strAcorgnameEN",
        "strAcorgnameTH",
        "strAcorginitialEN",
        "strAcorginitialTH",
      ],
      typeSchema: typeSchema,
      blnAltDupicateAcademic: false,
      blnAllDupStatus: false, //ชื่อซ้ำ และสถานะปิด
      blnAllDup: false, //ชื่อซ้ำ และสถานะเปิด
      request: request,
      timerCountHover: 0,
      ListAcademic: {},
      Listcampus: {},
      formStateby: {},
      listSearch: {},
      action: "Insert",
      strSMSGsuccess: "",
      blnAltStatus: false, //Alt Status Success
      blnAltStatusunsucess: false, //Alt Status unsuccess
      blnAltLoadingData: false, //Alt Status logint
      blnAltLoading: false, //Alt Status logint
      blnnotedit: false,
      blnnotdelete: false,
      blnnotaddchild: false,
      blnstatusOpent: false,
      hover: false, //hover alt
      blnopenConfigDisplay: false,
      intCampusId: null, //รหัส campus
      AcademicId: null,
      strCampusNameENLabel: "", //ชื่อวิทยาเขต
      strCampusNameTHLabel: "", //ชื่อวิทยาเขต
      formdata: {},
      formdataDummy: {},
      blnOpenform: false,
      activeFiltersSelected: [],
      strRootname: "",
      strAcademicsRoot: [], //เก็บ Root ทั้งหมด
      RootID: "", //เก็บ RootID
      ListDummy: {},
      isParent: false, //true ต้องเปลี่ยนสถานะแม่ false ไม่ต้องเปลี่ยนสถานะแม่
      intChangeStatusAcademic: null,
      dataRoot: [],
      CheckDeletedData: false, //เช็คว่าข้อมูลถูกลบไปแล้วหรือไม่
      blnOpenFilter: false,
      DropdownItem: [],
      updateAdsInventories: null,
      listAcademicFilter: [
        {
          list: [
            { name: "19" },
            {
              data: [
                { id: null, lang: "79" }, //all
                { id: 3, lang: "61" }, //open
                { id: 2, lang: "170" }, //In process
                { id: 1, lang: "171" }, //Suspend
                { id: 0, lang: "62" }, //close
              ],
            },
            { state: "intAcorgstatus" },
          ],
        },
      ],
      msgalertDataDuplicate: [],
      errorMsg: "",
    };
  },
  components: {
    Header,
    Form,
    AgengyTable,
    Filter,
    AlertPopup,
    AltStatus,
    Relate,
    Alert,
    Dropdown,
  },
  unmounted() {
    clearInterval(this.updateAdsInventories);
  },
  // mounted() {},
  beforeUpdate() {
    //เรียกข้อมูลจาก local
    this.locales = localStorage.getItem("language");
    //ถ้าไม่เจอค่า
    if (this.locales == null || this.locales == "") {
      //ตั้งค่าเริ่มต้นเป็นภาษาอังกฤษ
      this.locales = "en";
    }
  },
  beforeCreate() {
    AdsInventories.blnCloseform = false;
    // console.log(AdsInventories.blnCloseform);
  },
  mounted() {
    // console.log(this.$store.state.intCampusOfAcademic);

    axios
      .get(urlapi + APICampus.getCampusList)
      .then((res) => {
        this.Listcampus = res.data;
        if (
          this.$store.state.intCampusOfAcademic == null ||
          this.$store.state.intCampusOfAcademic == undefined
        ) {
          this.intCampusId = res.data.data[0].strID;
        } else {
          this.intCampusId = this.$store.state.intCampusOfAcademic;
        }

        this.$store.state.intCampusOfAcademic = this.intCampusId;
        // console.log(AdsInventories.blnCloseform);
        if (AdsInventories.blnCloseform == true) {
          this.processLoadingData();
        } else {
          this.updateAdsInventories = setInterval(() => {
            // console.log(AdsInventories.blnCloseform);
            if (AdsInventories.blnCloseform == false) {
              this.openform(false);
              AdsInventories.blnCloseform = true;
            }
          }, 300);
        }
      })
      .catch((e) => {
        this.blnAltLoadingData = true;
        this.timerCountHover = 0;
        this.timerEnabled = true;
        this.hover = false;
        setTimeout(() => {
          this.blnAltLoadingData = false;
        }, 5000);
        console.log(e);
      });
  },
  watch: {
    timerEnabled(value) {
      if (value) {
        setTimeout(() => {
          this.timerCount++;
        }, 1000);
      }
    },
    hover(value) {
      if (value == false) {
        setTimeout(() => {
          this.timerCountHover++;
        }, 1000);
      }
    },
    timerCountHover: {
      handler(value) {
        if (value < 2 && this.timerEnabled && this.hover == false) {
          setTimeout(() => {
            this.timerCountHover++;
          }, 1000);
        }
        if (value >= 2) {
          this.blnAltStatusunsucess = false;
          this.blnAltLoadingData = false;
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
    timerCount: {
      handler(value) {
        if (value < 10 && this.timerEnabled && this.progress < 75) {
          setTimeout(() => {
            this.timerCount++;

            this.progress += 5 * this.timerCount;
          }, 200);
        }
      },

      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
  methods: {
    // รับค่า ID ของ radio จาก Popup
    getIdRadio(state) {
      this.intChangeStatusAcademic = state;
      // console.log(state);
    },
    // Popup delete
    ConfirmDelete() {
      // console.log("click button delete");
      this.blnError = false;
      this.$refs.AlertPopup.blnShowMSG = false;
      this.$refs.AlertPopup.password = null;
      this.blnPopupConfirmDelete = !this.blnPopupConfirmDelete;
    },
    // skeleton loading ข้อมูลสำนักวิชาและหลักสูตร
    async processLoadingData() {
      this.listRelate = [];
      this.progress = 0;
      this.timerCount = 0;
      // console.log("processLoadingData");
      //โหลดข้อมูล
      await this.fetchListAcademic().then(() => {
        //กำหนด label
        this.setCampusLabel(this.intCampusId).then(() => {
          //เช็คลบข้อมูล
          // this.fetchCheckDeleteAcademic().then(() => {
          //จัดเรียงข้อมูล
          this.processData().then(() => {
            //delay 1วิ เพื่อปิด loadingbar
            setTimeout(() => {
              this.progress = 100;
            }, 2000);
            //this.progress = 100;
          });
          // });
        });
      });
    },
    //จัดการข้อมูลแล้วไปแสดงผลในตาราง
    async processData() {
      try {
        setTimeout(() => {
          //จัดกลุ่มข้อมูลแบบ Tree
          this.ListAcademic.data = group.unflatten(
            this.ListAcademic.data?.slice(),
            "intParentacorg"
          );
          //สำรองข้อมูล
          this.ListDummy = this.ListAcademic.data.slice();
          if (
            localStorage.getItem(this.configDisplay) == null ||
            localStorage.getItem(this.configDisplay) == "undefined"
          ) {
            localStorage.setItem(
              this.configDisplay,
              JSON.stringify(this.ListAcademic.defaultField)
            );
          }

          //บันทึกค่า configdisplay
          if (this.$refs.table != null) {
            // ค่า config ไม่เป็น undefined
            if (localStorage.getItem(this.configDisplay) != "undefined") {
              this.$refs.table.setSelectedList(
                JSON.parse(localStorage.getItem(this.configDisplay))
              );
            } else {
              // ค่า config เป็น undefined ให้ใช้ config จาก defaultfield
              // และ set ค่า config นั้นไว้
              this.$refs.table.setSelectedList(this.ListAcademic.defaultField);
              localStorage.setItem(
                this.configDisplay,
                JSON.stringify(this.ListAcademic.defaultField)
              );
            }
          }

          this.progress += 10;
          this.timerCount = 0;
          this.timerEnabled = true;
        }, 100);
        setTimeout(() => {
          if (this.$refs.filter != null) {
            this.$refs.filter.Clear();
          }
        }, 100);
      } catch (error) {
        this.blnAltLoadingData = true;
        this.timerCountHover = 0;
        this.timerEnabled = true;
        this.hover = false;
        setTimeout(() => {
          this.blnAltLoadingData = false;
        }, 5000);
        throw new Error(error);
      }
    },
    //เปิดปิด filter
    onOpenFilter(showFilter) {
      this.blnOpenFilter = showFilter;
    },

    //เปิดปิด form
    async openform(state, campus, parent) {
      // console.log(parent);
      this.activeFiltersSelected = "";

      if (state == false) {
        this.blnOpenform = false;
        this.isParent = false;
        this.strRootname = "";
        this.progress = 0;
        this.ListAcademic = [];
        this.formdata = {};
        this.formdataDummy = {};
        await this.processLoadingData();
        if (this.$refs.header != null) {
          this.$refs.header.onClear();
        }
        // console.log("State " + state);
      } else if (state == true) {
        this.blnloading = true;
        await this.fetchgetAcademicsRoot(parent);
        setTimeout(() => {
          this.blnloading = false;
        }, 600);
        if (this.action == "Insert") {
          await this.fetchcheckParent();
          if (this.isParent == true) {
            this.blnAltDupicateAcademic = true;
            this.blnOpenform = false;
            this.$refs.DupicateAcademic.password = null;
          } else {
            this.blnOpenform = true;
          }
          this.isParent = false;
          this.listRelate = [];
          this.formdata = {};
          this.formInactive = [];

          var activeDelete = {
            name: "blnDeleteinForm",
            inactive: true,
            tooltip: false,
          };

          this.formInactive.push(activeDelete);

          this.formdata = {
            strCampusid: campus,
            strCampusNameENLabel: this.strCampusNameENLabel,
            strCampusNameTHLabel: this.strCampusNameTHLabel,
            intParentacorg: parent.strID == undefined ? 0 : parent.strID,
            Rootname: this.strAcademicsRoot,
            intAcorgstatus: '',
          };
          //ถ้าแม่เป็น In process dropdown จะมีแค่ In process
          if (parent.intAcorgstatus == 2) {
            this.formInactive = [];
            let dropdownList = {
              name: "intAcorgstatus",
              enable: true,
            };
            this.formInactive.push(dropdownList);
            let dropdownList2 = {
              name: "intAcorgstatus",
              enablelist: true,
            };
            this.formInactive.push(dropdownList2);
          }
        }
        if (parent != 0 || this.action == "Update") {
          if (this.action == "Update") {
            this.blnOpenform = true;
          }
          this.formdata.strCampusNameENLabel = this.strCampusNameENLabel;
          this.formdata.strCampusNameTHLabel = this.strCampusNameTHLabel;
          // console.log(this.ListDummy);

          if (this.action == "Update" && this.strAcademicsRoot.length > 0) {
            this.formdata.Rootname = this.strAcademicsRoot;

            // Disable Dropdown
            if (this.blnischildinactive == true) {
              this.formInactive = [];
              let activeConfirm = {
                name: "intAcorgstatus",
                blnConfirm: true,
              };
              this.formInactive.push(activeConfirm);
            } else if (this.blnischildinactive == false) {
              this.formInactive = [];

              let inactiveStatus = {
                name: "intAcorgstatus",
                inactive: false, //enable
              };
              let activeConfirm = {
                name: "intAcorgstatus",
                blnConfirm: true,
              };
              this.formInactive.push(activeConfirm);
              this.formInactive.push(inactiveStatus);
            }

            // checkStatus
            if (this.parentstatus == 1) {
              //ลูก Open
              this.formInactive = [];
              let dropdownList = {
                name: "intAcorgstatus",
                inactive: true,
              };
              this.formInactive.push(dropdownList);
              let activeConfirm = {
                name: "intAcorgstatus",
                blnConfirm: true,
              };
              this.formInactive.push(activeConfirm);
            } else if (this.parentstatus == 2) {
              //ลูก In process
              this.formInactive = [];
              let dropdownList = {
                name: "intAcorgstatus",
                enable: true,
              };
              this.formInactive.push(dropdownList);
              let activeConfirm = {
                name: "intAcorgstatus",
                blnConfirm: true,
              };
              this.formInactive.push(activeConfirm);
            } else if (this.parentstatus == 3) {
              //ลูก close ,susprend
              this.formInactive = [];
              let dropdownList = {
                name: "intAcorgstatus",
                enablelist: true,
              };
              this.formInactive.push(dropdownList);
              let activeConfirm = {
                name: "intAcorgstatus",
                blnConfirm: true,
              };
              this.formInactive.push(activeConfirm);
            }
          }

          // let root =
          //   this.action === "Update"
          //     ? this.formdata
          //     : this.ListDummy.filter((ele) => {
          //         console.log(ele.strID);
          //         return ele.strID === parent;
          //       });

          this.strRootname =
            (this.action == "Insert" ? this.$t("237") : this.$t("238")) +
            " " +
            (this.locales == "en"
              ? parent.strAcorgnameEN
              : parent.strAcorgnameTH);
          // console.log(this.strRootname);
          try {
            //ถ้ามีการใช้งานอยู่ หรือ ไม่สามารถลบได้
            if (this.listRelate.used == true || this.blnCanDelete == false) {
              var inactiveDelete = {
                name: "blnDeleteinForm",
                inactive: true,
                tooltip: false,
              };
              this.formInactive.push(inactiveDelete);
            }
          } catch (error) {
            console.log(error);
          }
        }
      }
      // this.blnOpenform = state;
    },
    // set label campus เพื่อแสดงตอนหน้า form
    async setCampusLabel(intCampusId) {
      try {
        var cp = this.Listcampus.data.filter(function (elem) {
          // console.log(intCampusId.toString());
          if (elem.strID.toString() === intCampusId.toString()) {
            return true;
          }
        });

        this.strCampusNameENLabel = cp[0].strCampusNameEN;
        this.strCampusNameTHLabel = cp[0].strCampusNameTH;

        //console.log("setCampusLabel "+this.formdata.strCampusNameEN);
      } catch (error) {
        this.blnAltLoadingData = true;
        this.timerCountHover = 0;
        this.timerEnabled = true;
        this.hover = false;
        setTimeout(() => {
          this.blnAltLoadingData = false;
        }, 5000);
        throw new Error(error);
      }
    },
    // เลือกCampus
    async selectCampus(campusId) {
      this.intCampusId = campusId.id;
      this.$store.state.intCampusOfDepartment = this.intCampusId;
      this.progress = 0;
      this.timerCount = 0;
      await this.setCampusLabel(this.intCampusId);
      this.progress = 0;
      this.processLoadingData();
    },
    //อัพเดตข้อมูล Acadmic
    async fetchUpdateAcadmic(data) {
      // console.log(data);
      if (this.$refs.header != null) {
        this.$refs.header.onClear();
      }
      let strAcorgnameEN = data.strAcorgnameEN;
      let strAcorginitialEN = data.strAcorginitialEN;
      let strAcorgnameTH = data.strAcorgnameTH;
      let strAcorginitialTH = data.strAcorginitialTH;
      let intAcorgstatus = data.intAcorgstatus;
      let intParentacorg = data.intParentacorg;
      let strCampusid = data.strCampusid;
      const formdata = {
        strAcorgnameEN,
        strAcorginitialEN,
        strAcorgnameTH,
        strAcorginitialTH,
        intAcorgstatus,
        intParentacorg,
        strCampusid,
      };
      let isEqual = JSON.stringify(this.formdataDummy) === JSON.stringify(data);
      // console.log(isEqual);
      if (isEqual == true) {
        if (this.AcademicId != null) {
          await axios
            .get(
              urlapi +
                apiacademic.getAcademicByID +
                this.intCampusId +
                "/" +
                this.AcademicId
            )
            .then((res) => {
              if (
                res.data.message ==
                "Not found ,Please check mapping Id in interface"
              ) {
                this.blnOpenform = false;
                this.blnnotedit = true;
                this.progress = 0;
                setTimeout(() => {
                  this.blnnotedit = false;
                }, 5000);
                setTimeout(() => {
                  this.processLoadingData();
                }, 2000);
              } else {
                this.blnOpenform = false;
                this.blnAltStatus = true;
                this.strSMSGsuccess = "27";
                this.progress = 0;
                setTimeout(() => {
                  this.processLoadingData();
                  this.blnAltStatus = false;
                }, 2000);
              }
            })
            .catch(() => {
              this.blnAltStatusunsucess = true;
              this.timerCountHover = 0;
              this.timerEnabled = true;
              setTimeout(() => {
                this.blnAltStatusunsucess = false;
              }, 5000);
            });
        }
      }

      if (isEqual == false) {
        await axios
          .put(
            urlapi +
              apiacademic.updateAgencyAcademic +
              this.intCampusId +
              "/" +
              this.AcademicId +
              "?MemberID=" +
              AdsInventories.userinfo.intMemberID +
              (data.confirmintAcorgstatus !== undefined
                ? "&Confirm=" + data.confirmintAcorgstatus
                : '"&Confirm=" + false'),
            formdata
          )
          .then((res) => {
            // console.log(res);
            // console.log("MSG" + res.data.message);
            if (res.data.edited == true) {
              this.blnAltDupicateAcademic = false;
              this.blnAllDup = false;
              this.blnAllDupStatus = false;
              this.blnOpenform = false;
              this.blnAltStatus = true;
              this.strSMSGsuccess = "27";

              setTimeout(() => {
                this.blnAltStatus = false;
              }, 2000);
              this.progress = 0;
              //delay 1 sec
              setTimeout(() => {
                //ถ้าบันทึกสำเร็จ ทำการเรียกข้อมูลใหม่อีกครั้ง
                this.processLoadingData();
              }, 1000);
            }
            if (res.data.edited == false) {
              //ถ้าไม่สามารถ edit ได้ แล้วชื่อไม่ซ้ำ
              if (
                res.data.message ==
                "Cannot edit ,Please check mapping Id in interface"
              ) {
                // console.log("ไม่ซ้ำ");
                this.blnOpenform = false;
                this.blnnotedit = true;
                this.progress = 0;
                setTimeout(() => {
                  this.blnnotedit = false;
                }, 5000);
                setTimeout(() => {
                  this.processLoadingData();
                }, 1000);
              }
            }
            if (res.data.code === 500 || res.data.edited == false) {
              this.blnAltStatusunsucess = true;
              this.timerCountHover = 0;
              this.timerEnabled = true;
              setTimeout(() => {
                this.blnAltStatusunsucess = false;
              }, 5000);
            }
          })
          .catch((error) => {
            this.blnAltStatusunsucess = true;
            this.timerCountHover = 0;
            this.timerEnabled = true;
            setTimeout(() => {
              this.blnAltStatusunsucess = false;
            }, 5000);
            console.log(error);
          });
      }
    },
    // confirm popUp ข้อมูลซ้ำ
    async confirmAltPopup(state) {
      // console.log(state);
      // console.log(this.formStateby);
      // console.log(this.$refs.DupicateAcademic.password);
      this.blnloadConfirmDelet = true;
      if (state == false) {
        this.blnAltDupicateAcademic = false;
        this.blnAllDup = false;
        this.blnAllDupStatus = false;
        this.intChangeStatusAcademic = null;
        if (this.$refs.DupicateAcademic != undefined) {
          this.$refs.DupicateAcademic.clearRadio();
        }
        this.blnloadConfirmDelet = false;
      }
      if (this.action == "Insert") {
        try {
          if (
            this.$refs.DupicateAcademic.password != null &&
            this.$refs.DupicateAcademic.password != ""
          ) {
            if (state !== false) {
              let _logindata = {
                username: AdsInventories.userinfo.strUsername.split("@")[0],
                password: state,
              };
              // console.log(_logindata);
              var res = await axios.post(
                urlapi + "/v1/api/Login/UserLoginConfirm",
                _logindata,
                {
                  headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, POST",
                    "Access-Control-Allow-Headers": "Content-Type",
                  },
                }
              );
              this.blnloadConfirmDelet = false;

              // console.log(res.data);
              this.intPinCount = res.data.pincode;
              this.strTel = res.data.tel;
              // ต้องเลือก radio สถานะก่อน
              if (this.intChangeStatusAcademic != null) {
                //ถ้า login สำเร็จ จะปิด popup
                if (res.data.logined == true) {
                  this.blnError = false;
                  this.blnchangeMsg = false;
                  this.blnShowPopup = false;
                  this.blnAltDupicateAcademic = false;
                  // ถ้าเลือก In process dropdown จะมีแค่ in process
                  if (this.intChangeStatusAcademic == 2) {
                    this.formInactive = [];
                    let dropdownList1 = {
                      name: "intAcorgstatus",
                      enable: true,
                    };
                    this.formInactive.push(dropdownList1);
                    let dropdownList2 = {
                      name: "intAcorgstatus",
                      enablelist: true,
                    };
                    this.formInactive.push(dropdownList2);
                    // console.log(this.formInactive);
                  }
                  // console.log(this.intChangeStatusAcademic);
                  this.blnOpenform = true;

                  var activeDelete = {
                    name: "blnDeleteinForm",
                    inactive: true,
                    tooltip: false,
                  };

                  this.formInactive.push(activeDelete);
                  return true;
                } else {
                  //ถ้าใส่ Pin ผิด
                  this.intWrongLogin += 1;
                  this.blnError = true;
                  this.blnchangeMsg = false;
                  if (this.intWrongLogin >= this.intPinCount) {
                    this.blnPopupConfirmDelete = true;
                    VueCookies.remove("UserInfo", "/", cookiesPath);
                  }
                  
                }
              } else {
                // console.log("กรุณาเลือกสถานะ");
                this.blnchangeMsg = true;
                this.blnError = true;
              }
            }
          }

          //ถ้ากด Cancel หรือ Overlay
          else {
            this.blnloadConfirmDelet = false;
            this.blnAltDupicateAcademic = false;
            this.blnchangeMsg = false;
            this.blnError = false;
            return true;
          }
        } catch (error) {
          this.blnloadConfirmDelet = false;
          console.log(error);
        }
      } else if (this.action == "Update") {
        this.blnloadConfirmDelet = false;
        this.blnAltDupicateAcademic = false;
        this.fetchUpdateAcadmic(this.formStateby);
      }
    },
    // ดึงข้อมูล Academic โดย ID
    async fetchGetAcademicbyId(AcademicId) {
      this.formInactive = [];
      var resused = await axios.get(
        urlapi + apiacademic.checkUsedAgency + AcademicId
      );
      // console.log(resused);

      this.listRelate = { ...resused.data };

      this.AcademicId = AcademicId;

      await axios
        .get(
          urlapi +
            apiacademic.getAcademicByID +
            this.intCampusId +
            "/" +
            AcademicId
        )
        .then((res) => {
          // console.log(res.data);
          if (
            res.data.message ==
            "Not found ,Please check mapping Id in interface"
          ) {
            this.blnOpenform = false;
            this.blnnotedit = true;
            this.progress = 0;
            setTimeout(() => {
              this.blnnotedit = false;
            }, 5000);
            setTimeout(() => {
              this.processLoadingData();
            }, 1000);
          } else {
            this.action = "Update";
            this.formdata = res.data.data;
            this.formdataDummy = { ...res.data.data };
            this.setCampusLabel(this.intCampusId);
            this.blnischildinactive = res.data.ischildactive;
            this.DropdownID = res.data.data.intAcorgstatus;
            this.blnCanDelete = res.data.iscandelete;
            this.parentstatus = res.data.parentstatus;

            //this.blnOpenform = true;
            this.openform(true, this.intCampusId, this.formdata);
          }
        });
    },
    // reloadpage ไปหน้า logout
    reloadpage() {
      Login.logout();
      //ไปยังหน้าแรก
      // setTimeout(() => {
      //   this.$router.push("/login");
      //   this.$router.go();
      // }, 100);
    },
    // เพิ่มข้อมูลหน่วยงานย่อย
    async addParent(campus, parent) {
      // console.log(parent.strID);

      await axios
        .get(
          urlapi +
            apiacademic.getAcademicByID +
            this.intCampusId +
            "/" +
            parent.strID
        )
        .then((res) => {
          // console.log(res.data);
          if (
            res.data.message ==
            "Not found ,Please check mapping Id in interface"
          ) {
            this.blnOpenform = false;
            this.blnnotaddchild = true;
            this.progress = 0;
            setTimeout(() => {
              this.blnnotaddchild = false;
            }, 5000);
            setTimeout(() => {
              this.processLoadingData();
            }, 1000);
          } else {
            // await this.fetchGetAcademicbyId(parent.strID);
            this.action = "Insert";
            this.openform(true, campus, parent);
          }
        });
    },

    // ตรวจสอบว่าต้องเปลี่ยนสถานะแม่หรือไม่
    async fetchcheckParent() {
      // console.log(urlapi + apiacademic.checkacademicparentstatus + this.RootID);
      await axios
        .get(urlapi + apiacademic.checkacademicparentstatus + this.RootID)
        .then((resRoot) => {
          // console.log(resRoot.data);
          this.isParent = resRoot.data.isclose;
        });
    },

    //เปลี่ยนสถานะแม่
    async fetchchangeAcademicsStatus(data) {
      data.intAcorgparentstatus = this.intChangeStatusAcademic;
      // console.log("เปลี่ยนสถานะแม่" + data);

      try {
        var res = await axios.post(
          urlapi +
            apiacademic.insertAcademic +
            "?MemberID=" +
            AdsInventories.userinfo.intMemberID +
            "&Confirm=true",
          data
        );
        // console.log("res.data.inserted " + res.data.inserted);
        if (res.data.inserted == true) {
          this.blnOpenform = false;
          this.blnAltStatus = true;
          this.strSMSGsuccess = "26";
          this.blnAltDupicateAcademic = false;
          this.blnAllDup = false;
          this.blnAllDupStatus = false;
          setTimeout(() => {
            this.blnAltStatus = false;
          }, 2000);
          this.progress = 0;
          //delay 1 sec
          setTimeout(() => {
            //ถ้าบันทึกสำเร็จ ทำการเรียกข้อมูลใหม่อีกครั้ง
            this.processLoadingData();
          }, 1000);
        }
        if (res.data.code === 500 || res.data.inserted == false) {
          this.blnAltStatusunsucess = true;
          this.timerCount = 0;
          this.timerEnabled = true;
          setTimeout(() => {
            this.blnAltStatusunsucess = false;
          }, 5000);
        }
      } catch (error) {
        this.blnAltStatusunsucess = true;
        this.hover = false;
        this.timerEnabled = true;
        setTimeout(() => {
          this.blnAltStatusunsucess = false;
        }, 5000);
        throw new Error(error);
      }
    },

    //แสดงรูท
    async fetchgetAcademicsRoot(AcademicID) {
      this.blnOpenform = false;
      this.strAcademicsRoot = [];
      this.RootID =
        AcademicID.strID == undefined ? AcademicID : AcademicID.strID;

      await axios
        .get(urlapi + apiacademic.getAcademicsRoot + this.RootID)
        .then((resRoot) => {
          if (resRoot.data.data.length > 0) {
            this.strAcademicsRoot = resRoot.data.data;
          } else if (resRoot.data.data.length == 0) {
            this.strAcademicsRoot = [];
          }
        });
    },

    //ลบข้อมูลด้วย ID
    async fetchDeleteAcademic(state) {
      try {
        this.blnloadConfirmDelet = true;
        if (state === false) {
          // console.log(state);
          this.blnPopupConfirmDelete = !this.blnPopupConfirmDelete;
          this.blnloadConfirmDelet = false;
        } else {
          if (
            this.$refs.AlertPopup.password != null &&
            this.$refs.AlertPopup.password != ""
          ) {
            var strUsername = AdsInventories.userinfo.strUsername.split("@");
            var reslogin = await Login.login(
              strUsername[0],
              state,
              axios,
              urlapi
            );
            var login = {
              username: strUsername[0],
              password: state,
            };

            // console.log(reslogin);
            if (reslogin.logined == true) {
              this.intPinCount = reslogin.pincode;
              this.strTel = reslogin.tel;
              // this.blnPopupConfirmDelete = false;
              // console.log(
              //   apiacademic.deleteAcademic +
              //     this.intCampusId +
              //     "/" +
              //     this.AcademicId
              // );
              await axios
                .put(
                  urlapi +
                    apiacademic.deleteAcademic +
                    this.intCampusId +
                    "/" +
                    this.AcademicId,
                  login
                )
                .then((res) => {
                  // console.log(res.data);
                  if (res.data.candelete == true) {
                    this.blnError = false;
                    this.blnPopupConfirmDelete = false;
                    this.blnAltStatus = true;
                    this.strSMSGsuccess = "28";
                    //delay 1 sec
                    setTimeout(() => {
                      this.blnAltStatus = false;
                    }, 1000);
                    this.blnOpenform = false;
                    this.progress = 0;
                    setTimeout(() => {
                      this.processLoadingData();
                    }, 1000);
                    // this.openform(false, 0, 0);
                    // this.$refs.table.checkstateofdelete(true);
                  } else if (res.data.candelete == false) {
                    this.blnOpenform = false;
                    this.timerCountHover = 0;
                    this.timerEnabled = true;
                    this.hover = false;
                    this.blnPopupConfirmDelete = false;
                    // reset PIN หลังจากDelete
                    this.$refs.AlertPopup.password = null;
                    //ถ้าลบไปแล้ว ไม่สามารถลบซ้ำได้อีก
                    if (
                      res.data.message ==
                      "Cannot delete ,Please check mapping Id in interface"
                    ) {
                      //ข้อมูลนี้เพิ่งถูกลบไป ไม่สามารถลบได้
                      this.blnnotdelete = true;
                      setTimeout(() => {
                        this.blnnotdelete = false;
                      }, 5000);
                      this.progress = 0;
                      setTimeout(() => {
                        this.processLoadingData();
                      }, 1000);
                    }
                    if (
                      res.data.message == "Academic data is open cannot delete"
                    ) {
                      //ข้อมูลนี้มีสถานะเป็นเปิด ไม่สามารถลบได้
                      this.blnstatusOpent = true;
                      setTimeout(() => {
                        this.blnstatusOpent = false;
                      }, 5000);
                      this.progress = 0;
                      setTimeout(() => {
                        this.processLoadingData();
                      }, 1000);
                    } else {
                      //ข้อมูลนี้เพิ่งถูกใช้งาน ไม่สามารถลบได้
                      this.blnAltLoadingData = true;
                      setTimeout(() => {
                        this.blnAltLoadingData = false;
                      }, 5000);
                      this.progress = 0;
                      setTimeout(() => {
                        this.processLoadingData();
                      }, 1000);
                    }
                    // this.fetchGetAcademicbyId(this.AcademicId);
                  } else {
                    this.blnAltLoadingData = true;
                    this.timerCountHover = 0;
                    this.timerEnabled = true;
                    this.hover = false;
                    this.blnPopupConfirmDelete = false;
                    // reset PIN หลังจากDelete
                    this.$refs.AlertPopup.password = null;
                    setTimeout(() => {
                      this.blnAltLoadingData = false;
                    }, 5000);
                  }
                })
                .catch((e) => {
                  this.blnAltLoadingData = true;
                  this.timerCountHover = 0;
                  this.timerEnabled = true;
                  this.hover = false;
                  setTimeout(() => {
                    this.blnAltLoadingData = false;
                  }, 5000);
                  console.log(e);
                });
              // reset PIN หลังจากDelete
              this.$refs.AlertPopup.password = null;
            } else {
              this.intPinCount = reslogin.pincode;
              this.strTel = reslogin.tel;
              this.intWrongLogin = reslogin.WrongLogin;
              this.errorMsg = this.$t("357", {
                number: this.intPinCount - this.intWrongLogin,
              });
              this.blnError = true;
              this.$refs.AlertPopup.blnShowMSG = true;
              if (this.intWrongLogin >= this.intPinCount) {
                VueCookies.remove("UserInfo", "/", cookiesPath);
              }
              // this.$refs.table.checkstateofdelete(false);
              // setTimeout(() => {
              //   this.processLoadingData();
              // }, 1000);
            }
            this.blnloadConfirmDelet = false;
          } else {
            this.blnloadConfirmDelet = false;
            this.errorMsg = this.$t("202", { fieldname: "(PIN)" });
            this.blnError = true;
            this.$refs.AlertPopup.blnShowMSG = true;
          }
        }
      } catch (error) {
        this.$refs.AlertPopup.blnShowMSG = true;
        this.blnloadConfirmDelet = false;
        // this.blnAltLoadingData = true;
        // this.timerCountHover = 0;
        // this.timerEnabled = true;
        // this.hover = false;
        console.log(error);
      }
    },
    async fetchCheckDupicateUpdateAcademic(data) {
      // delete data.strID;
      // console.log(data);

      if (this.strAcademicsRoot.length > 0) {
        await this.fetchcheckParent();
      }
      this.formStateby = data;
      try {
        var res = await axios.post(urlapi + apiacademic.duplicate, data);
        // console.log(res);

        // console.log("academicorgnameTH " + res.data.academicorgnameTH);
        // console.log("academicorgnameEN " + res.data.academicorgnameEN);
        if (res.data.academicorgnameTH == true) {
          let Message = { name: "strAcorgnameTH", msg: "221" };
          this.msgalertDataDuplicate.push(Message);
        }
        if (res.data.academicorgnameEN == true) {
          let Message = { name: "strAcorgnameEN", msg: "221" };
          this.msgalertDataDuplicate.push(Message);
        }
        this.$refs.form.detectalertDataDuplicate(this.msgalertDataDuplicate);
        if (
          res.data.academicorgnameEN == false &&
          res.data.academicorgnameTH == false
        ) {
          this.$refs.form.detectalertDataDuplicate(this.msgalertDataDuplicate);

          if (this.action == "Insert") {
            await this.fetchInsertAcademic(data);
          } else if (this.action == "Update") {
            await this.fetchUpdateAcadmic(data);
          }
        }
      } catch (error) {
        console.log(error);
        this.blnAltStatusunsucess = true;
        this.timerCountHover = 0;
        this.timerEnabled = true;
        this.hover = false;
        setTimeout(() => {
          this.blnAltStatusunsucess = false;
        }, 5000);
        throw new Error(error);
      }
      return true;
    },
    //ตรวจสอบข้อมูลซ้ำ
    async fetchCheckDupicateAcademic(data) {
      if (this.strAcademicsRoot.length > 0) {
        await this.fetchcheckParent();
      }
      this.formStateby = data;
      // console.log(data);

      try {
        var res = await axios.post(urlapi + apiacademic.duplicate, data);
        // console.log(res);
        if (
          res.data.academicorgnameEN == false &&
          res.data.academicorgnameTH == false
        ) {
          if (this.action == "Insert") {
            if (this.isParent == false) {
              //แม่เปิด
              // console.log(data);
              await this.fetchInsertAcademic(data); //data แม่เป็น close?
            } else if (this.isParent == true) {
              //แม่ปิด
              this.dataRoot = data;
              this.dataRoot.intAcorgparentstatus = this.intChangeStatusAcademic; //เปลี่ยนสถานะแม่ตามที่เลือก
              await this.fetchchangeAcademicsStatus(this.dataRoot);
            }
          } else if (this.action == "Update") {
            await this.fetchUpdateAcadmic(data);
          }
        } else {
          this.msgalertDataDuplicate = [];
          if (res.data.academicorgnameTH == true) {
            let Message = { name: "strAcorgnameTH", msg: "221" };
            this.msgalertDataDuplicate.push(Message);
          }
          if (res.data.academicorgnameEN == true) {
            let Message = { name: "strAcorgnameEN", msg: "221" };
            this.msgalertDataDuplicate.push(Message);
          }

          this.$refs.form.detectalertDataDuplicate(this.msgalertDataDuplicate);
        }
      } catch (error) {
        this.blnAltStatusunsucess = true;
        this.timerCountHover = 0;
        this.timerEnabled = true;
        this.hover = false;
        setTimeout(() => {
          this.blnAltStatusunsucess = false;
        }, 5000);
        throw new Error(error);
      }
      return true;
    },
    // popUp ยืนยัน การเพิ่มข้อมูล
    async confirmInsert(event) {
      // console.log(event);
      if (event == true) {
        await this.fetchInsertAcademic();
      }
    },
    //ตรวจสอบว่า Academic สามารถลบได้หรือไหม
    async CheckDeletedata(data) {
      // console.log(data.intParentacorg);
      if (data.intParentacorg == 0) {
        this.CheckDeletedData = false;
      } else if (data.intParentacorg != 0 || data.intParentacorg != null) {
        await axios
          .get(
            urlapi +
              apiacademic.getAcademicByID +
              this.intCampusId +
              "/" +
              data.intParentacorg
          )
          .then((res) => {
            if (
              res.data.message ==
              "Not found ,Please check mapping Id in interface"
            ) {
              this.CheckDeletedData = true;
              this.blnOpenform = false;
              this.blnnotaddchild = true;
              this.progress = 0;
              setTimeout(() => {
                this.blnnotaddchild = false;
              }, 5000);
              setTimeout(() => {
                this.processLoadingData();
              }, 2000);
            }
          });
      }
    },
    // เพิ่มข้อมูล Academic
    async fetchInsertAcademic(data) {
      await this.CheckDeletedata(data);
      if (this.CheckDeletedData == false) {
        this.formdata.strCampusid = this.intCampusId;
        // console.log(data);
        try {
          const convertData = {
            strID: 0,
            strAcorgnameEN: data.strAcorgnameEN,
            strAcorginitialEN: data.strAcorginitialEN,
            strAcorgnameTH: data.strAcorgnameTH,
            strAcorginitialTH: data.strAcorginitialTH,
            intAcorgstatus: data.intAcorgstatus,
            intParentacorg: data.intParentacorg,
            strCampusid: data.strCampusid.toString(),
            intAcorgparentstatus: 0,
            intLevel:
              data.Rootname[0] == undefined ? 0 : data.Rootname[0].intLevel,
          };
          // console.log(convertData);
          var res = await axios.post(
            urlapi +
              apiacademic.insertAcademic +
              "?MemberID=" +
              AdsInventories.userinfo.intMemberID +
              "&Confirm=true",
            // data
            convertData
          );
          // console.log(urlapi +
          //     apiacademic.insertAcademic +
          //     "?MemberID=" +
          //     AdsInventories.userinfo.intMemberID +
          //     "&Confirm=true",
          //   data);
          // console.log(res);
          if (res.data.inserted == true) {
            this.blnAltDupicateAcademic = false;
            this.blnAllDup = false;
            this.blnAllDupStatus = false;
            this.blnOpenform = false;
            this.blnAltStatus = true;
            this.strSMSGsuccess = "26";
            setTimeout(() => {
              this.blnAltStatus = false;
            }, 2000);
            this.progress = 0;
            //delay 1 sec
            setTimeout(() => {
              //ถ้าบันทึกสำเร็จ ทำการเรียกข้อมูลใหม่อีกครั้ง
              this.processLoadingData();
            }, 1000);
          }
          if (res.data.code === 500 || res.data.inserted == false) {
            this.blnAltStatusunsucess = true;
            this.timerCountHover = 0;
            this.timerEnabled = true;
            this.hover = false;
            setTimeout(() => {
              this.blnAltStatusunsucess = false;
            }, 5000);
          }
        } catch (error) {
          this.blnAltStatusunsucess = true;
          this.hover = false;
          this.timerEnabled = true;
          setTimeout(() => {
            this.blnAltStatusunsucess = false;
          }, 5000);
          throw new Error(error);
        }
      }
    },
    // ดึง
    async fetchListAcademic() {
      //this.ListAcademic = null;
      // console.log(this.intCampusId);
      // console.log(urlapi + apiacademic.getAcademicList + this.intCampusId);
      this.ListAcademic = [];
      var call = await axios
        .get(urlapi + apiacademic.getAcademicList + this.intCampusId)
        .then((res) => {
          this.ListAcademic = res.data;
          // console.log(res.data);

          if (localStorage.getItem(this.configDisplay) == null) {
            localStorage.setItem(
              this.configDisplay,
              JSON.stringify(res.data.defaultField)
            );
          }
        })
        .catch((e) => {
          this.blnAltLoadingData = true;
          this.timerCountHover = 0;
          this.timerEnabled = true;
          this.hover = false;
          setTimeout(() => {
            this.blnAltLoadingData = false;
          }, 5000);
          console.log(e);
        });

      return call;
    },

    //บันทึกการตั้งค่า configdisplay
    onSaveConfigDisplay(SelectedList) {
      localStorage.setItem(this.configDisplay, JSON.stringify(SelectedList));
    },

    //เรียกบันทึกการตั้งค่า configdisplay
    reloadConfigDisplay() {
      if (this.$refs.table != null) {
        this.$refs.table.setSelectedList(
          JSON.parse(localStorage.getItem(this.configDisplay))
        );
      }
    },
    //ค้นหาข้อมูลใน table
    SearcAgency(event) {
      this.$refs.table.SearchListDataTable(event, this.strSearchField);
    },

    //ยกเลิกการค้นหาข้อมูล
    clean() {
      this.SearcAgency("");
      //this.filteredData(this.activeFiltersSelected);
    },
    //filter ข้อมูล
    filteredData(activeFilters) {
      // console.log(activeFilters);

      this.blnOpenFilter = false;
      //กำหนดรูปแบบการ filter
      this.activeFiltersSelected = activeFilters;
      //ตั้งค่าเริ่มต้น  highlight,expend เป็น false
      Object.values(this.ListDummy).forEach((ele) => {
        ele.highlight = false;
        ele.expend = false;
      });
      //dummy ข้อมูล

      var dummy = { ...this.ListDummy };
      // console.log(dummy);
      //เรียกฟังก์ชัน filter
      if (activeFilters.length != 0) {
        this.filter(activeFilters, dummy);
      } else {
        // this.ListParameter.data = this.ListDummy.data;
        this.filter(activeFilters, dummy);
        if (this.$refs.table != null) {
          this.$refs.table.onClean();
        }
      }
      // console.log(this.ListAcademic.data);
      //call tabel tree component get function setIndexnumber
      this.$refs.table.setIndexnumber();
    },
    //Filter รับค่า จากตัวกรองกับข้อมูลชุดเดิม
    filter(activeFilters, ListDummy) {
      this.blnOpenFilter = false;
      this.ListAcademic.data = Object.values(ListDummy).slice();

      this.ListAcademic.data = this.filterByList(
        this.ListAcademic.data,
        activeFilters
      );

      // for (var field in activeFilters) {

      //   //    this.ListAcademic.data = this.filterByList(
      //   //   this.ListAcademic.data,
      //   //   activeFilters[field].state,
      //   //   activeFilters[field].id
      //   // );
      //   console.log(this.ListAcademic.data);
      //   // this.ListAgency.data = this.ListAgency.data.filter(
      //   //   (elem) => elem[activeFilters[field].state] == activeFilters[field].id
      //   // );
      // }

      if (this.$refs.table != null) {
        this.$refs.table.onClean();
      }

      this.blnOpenFilter = false;
      this.ListDummy = ListDummy;
    },
    // filter ข้อมูล โดย list ข้อมูล
    filterByList(array, state) {
      const getNodes = (result, object) => {
        if (Array.isArray(object.children)) {
          const children = object.children.reduce(getNodes, []);

          if (children.length) {
            object.expend = true;
            result.push({ ...object, children });
            return result;
          }
        }

        if (this.filterSelected(object, state)) {
          object.highlight = true;
          object.expend = true;
          result.push(object);
          return result;
        }

        return result;
      };

      return array.slice().reduce(getNodes, []);
    },
    // เลือก filter
    filterSelected(item, filters) {
      // console.log("filterSelected");
      for (var field in filters) {
        if (
          (item[filters[field].state]
            .toString()
            .toLowerCase()
            .indexOf(filters[field].id.toString().toLowerCase()) !==
            -1) ==
          true
        ) {
          return true;
        }
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_custom.scss";
.form-size {
  width: 950px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 150ms ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.zoom-enter-from,
.zoom-leave-to {
  opacity: 0;
}
.zoom-enter-active {
  animation: bounce-in 150ms;
}
.zoom-leave-active {
  opacity: 0;
}
@keyframes bounce-in {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
.move-to-top-level .layout:nth-child(odd) {
  order: 2;
  margin-bottom: 16px !important;
}
.select-campus {
  width: 350px !important;
}
.form-fix {
  // max-width: max-content;
  width: 820px;
}
</style>
