const apiacademic = {
    insertAcademic: "/v1/api/AdsAcademicOrg/InsertAcademicorg",
    updateAgencyAcademic: "/v1/api/AdsAcademicOrg/UpdateAcademicorg/",
    deleteAcademic: "/v1/api/AdsAcademicOrg/DeleteAcdemicorg/",
    getAcademicList: "/v1/api/AdsAcademicOrg/AcademicorgList/",
    getAcademicByID: "/v1/api/AdsAcademicOrg/AcademicorgById/",
    duplicate: "/v1/api/AdsAcademicOrg/CheckduplicateAcademicorg",
    duplicateUpdate: "/v1/api/AdsAcademicOrg/CheckduplicateAcademicorg",
    checkdDeleteAcademic: "/v1/api/AdsAcademicOrg/CheckDeleteAcademicorg/",
    checkUsedAgency:"/v1/api/AdsAcademicOrg/CheckUsageAcademicorg/",
    getAcademicsRoot: "/v1/api/AdsAcademicOrg/GetAcademicRoot/",
    checkacademicparentstatus:"/v1/api/AdsAcademicOrg/GetAcademicStatus/"
  };
  
  export default apiacademic;
  