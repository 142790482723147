export const typeSchema = [
  {
    group: [
      {
        class: "row row m-0 gx-3 p-0",
        group: [
          {
            class: "row row m-0 gx-3 p-0",
            group: [
              {
                class: "d-flex justify-content-between ",
                component: [
                  {
                    type: "HeadLabel",
                    label: "2", // Campus
                    class: "fnt-medium mb-4 fnt-subtitle",
                  },
                ],
              },
              {
                group: [
                  {
                    class: "mb-4 bg-black-200 p-3 rounded-2 mx-0 p-0",
                    component: [
                      {
                        label: "", //Department information
                        type: "HeadLabel",
                        class: "fnt-regular fnt-body",
                        datavalue: "strCampusNameTHLabel",
                      },
                      {
                        label: "", //Department information
                        type: "HeadLabel",
                        class: "fnt-regular fnt-body",
                        datavalue: "strCampusNameENLabel",
                      },
                    ],
                  },
                ],
              },
              {
                group: [
                  {
                    class: "mb-3 mx-0 p-0",
                    component: [
                      {
                        label: "12",
                        type: "DepartmentRoot",
                        datavalue: "Rootname",
                      },
                    ],
                  },
                ],
              },
              {
                class: "row",
                group: [
                  {
                    class: "col-12 d-flex justify-content-between mb-4",
                    component: [
                      // Academic unit Label
                      {
                        name: "420",
                        label: "201",
                        type: "HeadLabel",
                        class: "fnt-regular fnt-subtitle ",
                      },
                      {
                        label: "34", // Require
                        type: "HeadLabel",
                        class: "text-danger-500 fnt-regular fnt-caption",
                        // require:' *'
                      },
                    ],
                  },
                ],
              },

              {
                class: "row",

                group: [
                  {
                    class: "col-lg-8 col-md-12 mb-3 pe-2",
                    component: [
                      // Academic name (TH)
                      {
                        label: "168",
                        type: "InputText",
                        datavalue: "strAcorgnameTH",
                        alertMsg: "202",
                        hint: "Please input in Thai",
                        name: "inpAcademicUnitNameTH",
                        validate: "letterTH",
                        request: true,
                        maxlength: 200,
                      },
                    ],
                  },
                  {
                    class: "col-lg-4 col-md-12 mb-3 ps-2",
                    component: [
                      // ชื่อหน่วย
                      {
                        label: "169",
                        type: "InputText",
                        datavalue: "strAcorginitialTH",
                        alertMsg: "202",
                        hint: "Please input in Thai",
                        name: "inpAcademicUnitInitialTH",
                        validate: "InitialTH",
                        request: true,
                        maxlength: 20,
                      },
                    ],
                  },
                ],
              },
              {
                class: "row",

                group: [
                  {
                    class: "col-lg-8 col-md-12 mb-3 pe-2",
                    component: [
                      // Academic name
                      {
                        label: "166",
                        type: "InputText",
                        datavalue: "strAcorgnameEN",
                        alertMsg: "202",
                        hint: "Please input in English",
                        name: "inpAcademicUnitNameEN",
                        validate: "letterEN",
                        request: true,
                        maxlength: 200,
                      },
                    ],
                  },
                  {
                    class: "col-lg-4 col-md-12 mb-3 ps-2",
                    component: [
                      // academic initial
                      {
                        label: "167",
                        type: "InputText",
                        datavalue: "strAcorginitialEN",
                        alertMsg: "202",
                        hint: "Please input in English",
                        name: "inpAcademicUnitInitialEN",
                        validate: "InitialEN",
                        request: true,
                        maxlength: 20,
                      },
                    ],
                  },
                ],
              },

              {
                class: "row",
                group: [
                  {
                    class: "col-lg-8 col-md-12 pe-2",
                    component: [
                      // Status
                      {
                        strName: "19",
                        star: "*",
                        type: "Selectdropdown",
                        List: true,
                        state: null,
                        datavalue: "intAcorgstatus",
                        blnSystemword: true,
                        blnAddress: false,
                        DropdownItem: [
                          { id: 3, lang: "61", intstatus: true, closestatus: true ,inprocess:false}, //open
                          { id: 2, lang: "170", intstatus: true, closestatus: false ,inprocess:true}, //In process
                          { id: 1, lang: "171", intstatus: false, closestatus: true ,inprocess:false}, //Suspend
                          { id: 0, lang: "62", intstatus: false, closestatus: true ,inprocess:false}, //close
                        ],

                        alertMsg: "284",
                        //sub: "1",
                        altMsgBox: "421",
                      },
                    ],
                  },
                ],
              },
              {
                class: "row",

                group: [
                  {
                    class:
                      "modal-footer border-0 justify-content-start pt-0 mt-6 ",
                    component: [
                      {
                        type: "Button",
                        name: "36", // OK
                        value: false,
                        state: "submit",
                        class: "btn-fix me-3",
                      },
                      {
                        type: "Button",
                        name: "35", // Cancel
                        value: false,
                        state: "cancel",
                        class: "btn-fix btn-cancel",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            class:
              "w-100 d-flex justify-content-center",
            group: [
              {
                component: [
                  {
                    class: "mt-3",
                    type: "DeleteinForm",
                    icon: "DeleteFill",
                    classBtnIcon: "fill-secondary",
                    datavalue: "blnDeleteinForm",
                    state: "delete",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

export const request = [
  "strAcorgnameEN",
  "strAcorginitialEN",
  "strAcorgnameTH",
  "strAcorginitialTH",
  "intAcorgstatus",
];
